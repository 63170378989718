import './style.css'
import * as THREE from 'three'
import Stats from 'three/examples/jsm/libs/stats.module.js';
import { GUI } from 'three/examples/jsm/libs/dat.gui.module.js';
import { TrackballControls } from 'three/examples//jsm/controls/TrackballControls.js';

let perspectiveCamera, orthographicCamera, controls, scene, renderer, stats;

const params = {
    orthographicCamera: false
};

const frustumSize = 400;

init();
animate();

// document.addEventListener('keydown', function (event){
// 	if (event.ctrlKey && event.key === 'a') {
// 		alert("About nanoarrays \n(html / js / css code here):\n\ndeveloped"+
// 		" by Andrew R. Garcia \non Mar 27, 2021")
// 	}
    
// 	});

function init() {

    const sizes = {
        width: 900,
        height: 225
    }


    const aspect = window.innerWidth / window.innerHeight;

    // perspectiveCamera = new THREE.PerspectiveCamera( 60, sizes.width / sizes.height );
    perspectiveCamera = new THREE.PerspectiveCamera( 60, aspect, 1, 1000 );
    perspectiveCamera.position.z = 900;
    perspectiveCamera.position.x = 300;
    perspectiveCamera.position.y = 150;

    orthographicCamera = new THREE.OrthographicCamera( frustumSize * aspect / - 2, frustumSize * aspect / 2, frustumSize / 2, frustumSize / - 2, 1, 1000 );
    orthographicCamera.position.z = 500;

    // world

    scene = new THREE.Scene();
    scene.background = new THREE.Color( 0xcccccc );
    scene.fog = new THREE.FogExp2( 0xcccccc, 0.002 );

    // const geometry = new THREE.CylinderBufferGeometry( 10, 10, 300, 12, 1 );
    //NANOWIRE DIMENSIONS: 165 x 2800 nm  (spacing 150 of nm) [J.J. Hill, K. Haller, B. Gelfand, and K.J. Ziegler. Eliminating Capillary Coalescence of Nanowire Arrays with Applied Electric Fields. ACS Appl. Mater. Inter. 2010, 2, 1992]
    const geometry = new THREE.CylinderBufferGeometry( 10, 10, 170, 30, 1 );

    const material = new THREE.MeshNormalMaterial();

    for ( let i = 0; i < 50; i ++ ) {
    for ( let j = 0; j < 50; j ++ ) {

        const mesh = new THREE.Mesh( geometry, material );
        // mesh.position.x = ( Math.random() - 0.5 ) * 1000;
        //mesh.position.y = ( Math.random() - 0.5 ) * 1000;
        // mesh.position.z = ( Math.random() - 0.5 ) * 1000;
        mesh.position.x = i*35;
        mesh.position.y = 0;
        mesh.position.z = j*35;

        mesh.updateMatrix();
        mesh.matrixAutoUpdate = false;
        scene.add( mesh );

    }}

    // lights



    const dirLight1 = new THREE.DirectionalLight( 0xffffff );
    dirLight1.position.set( 1, 1, 1 );
    scene.add( dirLight1 );

    const dirLight2 = new THREE.DirectionalLight( 0x002288 );
    dirLight2.position.set( - 1, - 1, - 1 );
    scene.add( dirLight2 );

    const ambientLight = new THREE.AmbientLight( 0x222222 );
    scene.add( ambientLight );

    // renderer

    renderer = new THREE.WebGLRenderer( { antialias: true } );
    renderer.setPixelRatio( window.devicePixelRatio );
    // renderer.setSize( window.innerWidth, window.innerHeight );
    renderer.setSize(sizes.width, sizes.height)
    document.body.appendChild( renderer.domElement );

    stats = new Stats();
    document.body.appendChild( stats.dom );

    //

    const gui = new GUI();
    gui.add( params, 'orthographicCamera' ).name( 'Ziegler Lab nanoarrays  (orthographic)' ).onChange( function ( value ) {

        controls.dispose();

        createControls( value ? orthographicCamera : perspectiveCamera );

    } );

    //
    

    
    window.addEventListener( 'resize', onWindowResize, false );

    createControls( perspectiveCamera );

}



function createControls( camera ) {

    controls = new TrackballControls( camera, renderer.domElement );

    controls.rotateSpeed = 1.0;
    controls.zoomSpeed = 1.2;
    controls.panSpeed = 0.8;

    controls.keys = [ 65, 83, 68 ];

}

function onWindowResize() {

    const aspect = window.innerWidth / window.innerHeight;

    perspectiveCamera.aspect = aspect;
    perspectiveCamera.updateProjectionMatrix();

    orthographicCamera.left = - frustumSize * aspect / 2;
    orthographicCamera.right = frustumSize * aspect / 2;
    orthographicCamera.top = frustumSize / 2;
    orthographicCamera.bottom = - frustumSize / 2;
    orthographicCamera.updateProjectionMatrix();

    renderer.setSize( window.innerWidth, window.innerHeight );

    controls.handleResize();

}

function animate() {

    requestAnimationFrame( animate );

    controls.update();

    stats.update();

    render();

}

function render() {

    const camera = ( params.orthographicCamera ) ? orthographicCamera : perspectiveCamera;

    renderer.render( scene, camera );

}